/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Aunque en Debian no se configura automáticamente Sudo como en Ubuntu, podemos configurarlo manualmente, con esto ganaremos comodidad pero perderemos en seguridad, esto ya depende de la elección de cada uno. Lo primero es instalar el paquete de sudo."), "\n", React.createElement(_components.p, null, "Como root escribimos esto en la consola."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">aptitude install sudo</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Después configuramos sudo:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">nano /etc/sudoers</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Añadimos esta línea al archivo, donde X es el nombre de usuario que queremos utilizar. X ALL=(ALL) ALL"), "\n", React.createElement(_components.p, null, "También podemos añadir esta línea para que no nos pida la contraseña cada vez que usemos sudo: Defaults !authenticate"), "\n", React.createElement(_components.p, null, "Guardamos con Ctrl+O y salimos con Ctrl+X."), "\n", React.createElement(_components.p, null, "Y ya podemos usar sudo fácilmente."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
